import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image' 
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from "../components/general/window";
import Layout from "../components/layout/layout"
import Head from '../components/general/head'
import { PageContainer, ContentWrapper, DeepBackground, HeadingWrapper, Title, Line, SubTitle, SolidDivider, SolidBackground, 
         TextureBackground, Container, MediaContainer, InfoBox, BigNumber, Top, Bottom, MediaText, MediaTitle, Description, BumperOffset, Bumper } from '../components/general/general'
import { HelpButton } from "../components/general/helpButton.js"
import Video from "../components/general/video"

import "@fontsource/raleway/900.css"
import "@fontsource/raleway/800.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/400.css"
import "@fontsource/work-sans/700.css"

const EntriesTemplate = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    return (
            <Layout>
                <Head title={`${props.data.contentfulCharacter.characterNamePossessive} Entries`}/>
            <PageContainer>
                <ContentWrapper>
                    <ParallaxProvider>
                        <Parallax
                            y={[-50, 50]}
                            slowerScrollRate
                            disabled={width <= 1024 }
                        >
                            <DeepBackground />
                        </Parallax>
                        
                        <Parallax
                            y={[100, 0]}
                            fasterScrollRate
                            disabled={width <= 1024 }
                        >
                            <HeadingWrapper>
                                <Title color={props.data.contentfulCharacter.characterColors.highlight}>{props.data.contentfulCharacter.characterName}</Title>
                                <Line>
                                    <StaticImage
                                        src="../images/lines/two-lines.png"
                                        alt="Two Curved Light Weight Line"
                                        layout="constrained"
                                        // css={`
                                        //     width: 65%;
                                        //     opacity: .8;
                                        // `}
                                    />
                                </Line>
                                <SubTitle>Diary Entries</SubTitle>
                            </HeadingWrapper> 
                        </Parallax>
                        <Parallax disabled={width <= 1024 }>
                            <SolidBackground>
                            {/* <SolidDivider /> */}
                                <TextureBackground>
                                    <Bumper>
                                        <HelpButton />
                                    </Bumper>
                                    <BumperOffset>
                                        
                                        <Container>
                                            {props.data.contentfulCharacter.characterEntries.entries.map((entry) => {
                                                return (
                                                    <MediaContainer id={entry.entryId} background={props.data.contentfulCharacter.characterColors.primary}>
                                                        {/* <Thumbnail
                                                            image={entry.entryThumbnail.gatsbyImageData}
                                                            alt={entry.entryThumbnail.description}
                                                        /> */}
                                                        <Video 
                                                            videoSrcURL={entry.videoSrcUrl.videoSrcUrl}
                                                            videoTitle={entry.videoTitle}
                                                        />
                                                        <script src="https://player.vimeo.com/api/player.js"></script>
                                                        <InfoBox color={props.data.contentfulCharacter.characterColors.highlight}>
                                                            <BigNumber color={props.data.contentfulCharacter.characterColors.highlight}>
                                                                <Top>
                                                                    ENTRY
                                                                </Top>
                                                                <Bottom>
                                                                    #{entry.entryNumber}
                                                                </Bottom>
                                                            </BigNumber>
                                                            <MediaText>
                                                                <MediaTitle>
                                                                    {entry.entryTitle}
                                                                </MediaTitle>
                                                                <Description>
                                                                    {entry.entryDescription}
                                                                </Description>
                                                            </MediaText>
                                                        </InfoBox>
                                                    </MediaContainer>
                                                )
                                            })}
                                        </Container>
                                    </BumperOffset>
                                </TextureBackground>
                            </SolidBackground>
                        </Parallax>
                    </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        contentfulCharacter(slug: {eq: $slug}) {
            characterName
            characterNamePossessive
            characterColors {
                primary
                highlight
            }
            characterEntries {
                entries {
                    entryThumbnail {
                        gatsbyImageData (
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                        description
                    }
                    entryNumber
                    entryId
                    entryNumberText
                    entryTitle
                    entryDescription
                    videoTitle
                    videoSrcUrl {
                        videoSrcUrl
                    }
                }
            }
        }
    }
`

export default EntriesTemplate